body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*

General variables

*/
/*

Mixins for multiple times usage

*/
/*

General variables

*/
/*

Mixins for multiple times usage

*/
/*

General Styles

*/
/*

Mixins for multiple times usage

*/
/*

General Styles

*/
body {
  background: #f7f7f7;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #6c6c6c;
  line-height: 150%; }

* focus {
  outline: none;
  border: none; }

a {
  color: #f62f5e;
  transition: all 0.25s ease; }
  a:hover, a:active {
    color: #2e2e2e;
    text-decoration: none; }

.heading-small {
  font-size: 12px;
  line-height: 200%; }

.top-bar {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 240%;
  color: #2e2e2e; }

h1,
.h1 {
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 150%;
  color: #2e2e2e; }
  @media (max-width: 767px) {
    h1,
    .h1 {
      font-size: 36px; } }

h2,
.h2,
h3,
.h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  color: #2e2e2e; }
  @media (max-width: 767px) {
    h2,
    .h2,
    h3,
    .h3 {
      font-size: 22px; } }

h3,
.h3 {
  font-size: 16px; }
  @media (max-width: 767px) {
    h3,
    .h3 {
      font-size: 16px; } }

.bg-white {
  background: #fff;
  color: #2e2e2e; }

.bg-black {
  background: #2e2e2e;
  color: #fff; }

.bg-red {
  background: #f62f5e;
  color: #fff; }

.bg-blue {
  background: #6eb2fb;
  color: #2e2e2e; }

.bg-orange {
  background: #f1ad3d;
  color: #2e2e2e; }

.bg-light {
  background: #f7f7f7;
  color: #2e2e2e; }

.bg-teal {
  background: #00d3ca;
  color: #2e2e2e; }

.bg-yellow {
  background: #effc90;
  color: #2e2e2e; }

.bg-green {
  background: #77db77; }

.bg-purple {
  background: #9013fe; }

.bg-yellow-dark {
  background: #f8e71c; }

.bg-gray {
  background: #fafafa; }

.black {
  color: #2e2e2e; }

.gray-dark {
  color: #6c6c6c; }

.gray-light {
  color: #b4b4b4; }

.red {
  color: #f62f5e; }

.white {
  color: #fff; }

.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background: #f62f5e;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.35s ease; }
  .btn:hover, .btn:focus {
    background: #fff;
    color: #f62f5e;
    border: 1px solid #f62f5e;
    outline: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none; }
  .btn:focus {
    background: #f62f5e;
    color: #fff; }
    .btn:focus:hover {
      background: #fff;
      color: #f62f5e; }

.btn-white {
  background: #fff;
  color: #f62f5e;
  border: 1px solid #f62f5e; }
  .btn-white:hover, .btn-white:focus {
    color: #fff;
    background: #f62f5e;
    border: 1px solid #f62f5e; }

.btn-lg {
  padding: 0.5rem 2rem; }

.btn-md {
  font-size: 1rem;
  padding: 0.5rem 1.5rem; }

.btn-sm {
  padding: 0.5rem 1rem; }

input,
select,
textarea {
  min-height: 50px;
  margin-bottom: 25px;
  padding: 0 15px;
  background: #fff;
  border: 1px solid #b4b4b4;
  color: #6c6c6c;
  font-weight: 600;
  border-radius: 10px; }
  @media (max-width: 576px) {
    input,
    select,
    textarea {
      min-width: 100%; } }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
    box-shadow: none; }

textarea {
  padding: 15px;
  min-height: 150px; }

input.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #b4b4b4; }

.success {
  border: 1px solid #77db77; }

.error {
  border: 1px solid #f62f5e; }

.sale,
.pop,
.hot,
.time {
  background: #00d3ca;
  color: #fff;
  width: auto;
  min-width: 50px;
  padding: 5px 10px;
  display: inline-block; }

.pop {
  background: #f1ad3d; }

.hot {
  background: #f62f5e; }

.time {
  background: #6eb2fb; }

.color-codes {
  padding: 0;
  margin: 0px; }
  .color-codes li {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 25px;
    cursor: pointer;
    border-radius: 50%; }
    @media (max-width: 767px) {
      .color-codes li {
        margin-bottom: 20px; } }
  .color-codes .active {
    position: relative; }
    .color-codes .active:before {
      content: "";
      position: absolute;
      left: -5px;
      top: -5px;
      z-index: -1;
      background: #fff;
      width: 30px;
      height: 30px;
      border: 2px solid #b4b4b4;
      border-radius: 50%; }
  .color-codes:last-child {
    margin-right: 0; }

.pagination-block {
  display: inline-block;
  padding: 0;
  margin: 0; }
  .pagination-block .back,
  .pagination-block .forward {
    background: #b4b4b4;
    border-radius: 50%;
    padding-bottom: 2px; }
    .pagination-block .back:hover, .pagination-block .back:focus,
    .pagination-block .forward:hover,
    .pagination-block .forward:focus {
      color: #2e2e2e; }
  .pagination-block .last_page {
    margin-left: 10px; }
  .pagination-block li.disabled {
    opacity: 0.5;
    pointer-events: none; }
  .pagination-block li {
    float: left;
    margin-right: 30px;
    color: #2e2e2e;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-top: 0px; }
    @media (max-width: 767px) {
      .pagination-block li {
        width: 20px;
        height: 20px;
        margin-right: 15px; } }
    .pagination-block li a {
      color: #2e2e2e; }
      .pagination-block li a:hover, .pagination-block li a:focus {
        color: #2e2e2e; }
  @media (max-width: 767px) {
    .pagination-block .forward {
      margin: 0 25px; }
    .pagination-block .back {
      margin: 0 22px 0 10px; } }
  .pagination-block .active {
    background: #f62f5e;
    color: #fff;
    border-radius: 50%; }
    .pagination-block .active a {
      color: #fff; }
      .pagination-block .active a:hover, .pagination-block .active a:focus {
        color: #fff; }

.pagination-circle {
  padding: 0;
  margin: 0; }
  .pagination-circle li {
    float: left;
    width: 23px;
    height: 23px;
    margin-right: 10px;
    background: #f7f7f7;
    border: 4px solid #6eb2fb;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.25s ease; }
    .pagination-circle li:hover {
      background: #6eb2fb;
      border: 4px solid #6eb2fb; }

.starts-block {
  padding: 0;
  margin: 0; }
  .starts-block li {
    float: left;
    margin-right: 10px; }
    .starts-block li a {
      color: #6c6c6c;
      font-size: 30px; }
      .starts-block li a:hover, .starts-block li a:focus {
        color: #f1ad3d; }
  .starts-block .active a {
    color: #f1ad3d; }

.radio-checkbox-block input {
  opacity: 0;
  float: left;
  margin: 0 0 0 -20px;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 5; }
  .radio-checkbox-block input:checked + span {
    background: #6eb2fb;
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    padding: 0px;
    float: left;
    position: relative;
    border: 1px solid #6eb2fb;
    border-radius: 5px; }
    .radio-checkbox-block input:checked + span:after {
      content: "\2713";
      position: absolute;
      z-index: 4;
      left: 3px;
      top: -3px;
      width: 10px;
      height: 10px;
      color: #fff; }

.radio-checkbox-block input[type="radio"]:checked + span:after {
  content: "";
  position: absolute;
  z-index: 4;
  left: 5px;
  top: 5px;
  width: 8px;
  height: 8px;
  color: #fff;
  background: white;
  border-radius: 50%; }

.radio-checkbox-block input[type="radio"] + span {
  border-radius: 50%; }

.radio-checkbox-block span {
  background: none;
  border: 1px solid #b4b4b4;
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  padding: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  float: left;
  border-radius: 5px; }

.radio-checkbox-block label {
  margin: 0 0 0 10px; }

.button-sizes {
  padding: 0;
  margin: 0; }
  .button-sizes li {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px; }
    .button-sizes li a {
      background: #b4b4b4;
      color: #6c6c6c;
      font-size: 18px;
      font-weight: fw-500;
      min-width: 70px;
      height: 35px;
      padding: 10px 15px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px; }
      .button-sizes li a:hover, .button-sizes li a:focus {
        background: #f62f5e;
        color: #fff; }

.product-image div {
  float: left;
  background: #b4b4b4;
  width: 425px;
  height: 520px;
  margin-right: 50px;
  box-shadow: 0 2px 3px -2px black;
  border: 1px solid #9e9e9e;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease; }
  @media (max-width: 767px) {
    .product-image div {
      margin-bottom: 30px;
      width: 100%; } }
  .product-image div:hover {
    box-shadow: 0px 10px 50px -5px #2e2e2e;
    background: #fff; }

/* Header Styles */
.header {
  display: flex; }
  .header .head-inner {
    position: relative; }
  .header .logo_block {
    width: auto;
    float: left;
    margin-top: 10px; }
    .header .logo_block a {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.1em;
      font-family: "Montserrat", sans-serif; }
      @media (max-width: 560px) {
        .header .logo_block a {
          font-size: 18px; } }
  .header nav {
    float: left;
    width: 78%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 1199px) {
      .header nav {
        width: 75%; } }
    @media (max-width: 991px) {
      .header nav {
        width: 100%;
        float: right;
        display: block; } }
    .header nav .navbar-nav .nav-item.active .nav-link {
      color: #f62f5e; }
    .header nav .navbar-nav .nav-link {
      color: #2e2e2e;
      font-size: 15px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      padding: 10px 20px; }
      @media (max-width: 991px) {
        .header nav .navbar-nav .nav-link {
          padding: 10px 0;
          border-bottom: 1px solid #2e2e2e; } }
      .header nav .navbar-nav .nav-link:hover {
        color: #f62f5e; }
  .header .head-right {
    width: auto;
    float: right;
    margin-top: 8px; }
    @media (max-width: 991px) {
      .header .head-right {
        position: absolute;
        right: 35px; } }
    .header .head-right ul li {
      float: left;
      margin-right: 15px; }
      .header .head-right ul li a {
        color: #2e2e2e; }
        .header .head-right ul li a:hover, .header .head-right ul li a:focus {
          color: #f62f5e; }
  .header .navbar-toggler {
    border: none;
    padding: 12px 0 0 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -35px; }
    @media (max-width: 560px) {
      .header .navbar-toggler {
        top: -33px; } }
    .header .navbar-toggler:hover, .header .navbar-toggler:focus {
      outline: none; }
      .header .navbar-toggler:hover .toggler-icon, .header .navbar-toggler:focus .toggler-icon {
        background: #f62f5e;
        cursor: pointer; }
    .header .navbar-toggler .toggler-icon {
      width: 20px;
      height: 2px;
      background: #6c6c6c;
      display: block;
      margin-bottom: 5px; }
  .header img {
    max-width: 100%;
    height: auto; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
body .header .open.navbar-collapse {
  display: inline-flex !important;
  top: 0;
  position: absolute;
  background: #fff3f3;
  width: 100%;
  left: 0;
  visibility: visible;
  /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
  box-shadow: 0px 1px 1px #61484e; }

.header nav.navbar {
  width: 100%;
  margin: 0; }

.product_filter_panel {
  position: relative;
  top: 20px; }
  .product_filter_panel h2 {
    text-transform: capitalize; }

body .header .head-right {
  margin-top: -68px; }

body .header .closed.navbar-collapse {
  visibility: hidden;
  /* hides sub-menu */
  opacity: 0;
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  transform: translateY(-2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; }

body .header .navbar-nav {
  flex-direction: column;
  display: inline-table;
  padding: 15px;
  width: 100%; }
  body .header .navbar-nav li {
    float: left;
    width: 33.3%;
    padding: 5px; }

.hamburger-menu {
  display: flex;
  position: absolute;
  top: 30px;
  right: 100px; }
  .hamburger-menu > div {
    left: 6px;
    top: 5px; }

.topbar {
  padding: 15px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  min-height: 50px;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background: #f7f7f7; }
  @media (max-width: 767px) {
    .topbar {
      min-height: inherit;
      padding: 5px 0; } }
  .topbar .register-block {
    float: left;
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .topbar .register-block {
        text-align: center;
        width: auto;
        margin-top: 0; } }
    .topbar .register-block .login-name-block {
      margin: 8px 0 8px 10px;
      font-weight: 600; }
      @media (max-width: 767px) {
        .topbar .register-block .login-name-block {
          margin: 0 0 0 10px; } }
      @media (max-width: 560px) {
        .topbar .register-block .login-name-block {
          font-size: 0.85rem; } }
    .topbar .register-block a {
      display: inline-block; }
      @media (max-width: 767px) {
        .topbar .register-block a {
          padding: 0 1rem; } }
  .topbar .topbar-menu {
    float: left;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991px) {
      .topbar .topbar-menu {
        width: 45%; } }
    @media (max-width: 767px) {
      .topbar .topbar-menu {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px; } }
    .topbar .topbar-menu ul {
      padding: 0;
      margin: 0; }
      .topbar .topbar-menu ul li {
        float: left;
        margin-right: 25px; }
        @media (max-width: 991px) {
          .topbar .topbar-menu ul li {
            margin-right: 10px; } }
        .topbar .topbar-menu ul li a {
          color: #2e2e2e; }
          .topbar .topbar-menu ul li a:hover, .topbar .topbar-menu ul li a:focus {
            color: #f62f5e; }
  .topbar .currency-block {
    float: left;
    padding-left: 30px; }
    @media (max-width: 1200px) {
      .topbar .currency-block {
        padding-left: 0; } }
  .topbar .price-block {
    float: right;
    margin-top: 8px; }
    @media (min-width: 767px) and (max-width: 991px) {
      .topbar .price-block {
        width: 40%;
        text-align: right; } }
    @media (max-width: 767px) {
      .topbar .price-block {
        margin-top: 0; } }
    @media (max-width: 560px) {
      .topbar .price-block span {
        display: none; } }
    .topbar .price-block a {
      color: #2e2e2e;
      padding: 0 0 0 10px; }
      @media (max-width: 767px) {
        .topbar .price-block a {
          padding: 0 1rem; } }
      .topbar .price-block a:hover, .topbar .price-block a:focus {
        color: #f62f5e; }
    .topbar .price-block .signout-block {
      display: inline-block; }
      .topbar .price-block .signout-block a {
        color: #f62f5e;
        padding-right: 0; }
        .topbar .price-block .signout-block a:hover, .topbar .price-block .signout-block a:focus {
          color: #2e2e2e; }

.header {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 65px;
  border-bottom: 1px solid #b4b4b4; }
  @media (max-width: 767px) {
    .header {
      top: 34px; } }
  .header .head-inner {
    position: relative; }
  .header .logo_block {
    width: auto;
    float: left;
    margin-top: 10px;
    cursor: pointer; }
    @media (max-width: 767px) {
      .header .logo_block {
        margin-top: 0; } }
    .header .logo_block a {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.1em;
      font-family: "Montserrat", sans-serif; }
      @media (max-width: 560px) {
        .header .logo_block a {
          font-size: 18px; } }
    .header .logo_block img {
      padding: 10px 0;
      max-width: 70%; }
      @media (max-width: 560px) {
        .header .logo_block img {
          max-width: 50%; } }
  .header nav {
    float: left;
    width: 66%;
    padding: 0;
    margin: 20px 0 0 -90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 1199px) {
      .header nav {
        width: 70%; } }
    @media (max-width: 991px) {
      .header nav {
        width: 100%;
        float: right;
        display: block;
        margin: 0; } }
    @media (min-width: 992px) {
      .header nav .navbar-collapse {
        display: block !important; } }
    .header nav .navbar-nav .nav-item .nav-link.active {
      color: #f62f5e; }
    .header nav .navbar-nav .nav-link {
      color: #2e2e2e;
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      padding: 10px 20px; }
      @media (max-width: 991px) {
        .header nav .navbar-nav .nav-link {
          padding: 10px 0;
          border-bottom: 1px solid #2e2e2e; } }
      .header nav .navbar-nav .nav-link:hover {
        color: #f62f5e; }
    @media (max-width: 991px) {
      .header nav .navbar-nav .nav-item:last-child a {
        border: none;
        padding-bottom: 50px; } }
  .header .head-right {
    width: auto;
    float: right;
    margin-top: 30px; }
    @media (max-width: 991px) {
      .header .head-right {
        position: absolute;
        right: 50px;
        margin-top: 45px; } }
    @media (max-width: 560px) {
      .header .head-right {
        margin-top: 30px;
        right: 45px; } }
    .header .head-right ul li {
      position: relative;
      float: left;
      margin-right: 15px; }
      .header .head-right ul li a {
        color: #2e2e2e; }
        .header .head-right ul li a:hover, .header .head-right ul li a:focus {
          color: #f62f5e; }
        .header .head-right ul li a .cart_items {
          position: absolute;
          border-radius: 50%;
          width: auto;
          height: auto;
          min-width: 20px;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          color: white;
          padding: 0 8px;
          bottom: 15px;
          right: -10px;
          background-color: #f62f5e; }
      .header .head-right ul li:last-child {
        margin-right: 0; }
  .header .navbar-toggler {
    border: none;
    padding: 12px 0 0 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -68px; }
    @media (max-width: 767px) {
      .header .navbar-toggler {
        top: -60px; } }
    @media (max-width: 560px) {
      .header .navbar-toggler {
        top: -55px; } }
    .header .navbar-toggler:hover, .header .navbar-toggler:focus {
      outline: none; }
      .header .navbar-toggler:hover .toggler-icon, .header .navbar-toggler:focus .toggler-icon {
        background: #f62f5e;
        cursor: pointer; }
    .header .navbar-toggler .toggler-icon {
      width: 20px;
      height: 2px;
      background: #6c6c6c;
      display: block;
      margin-bottom: 5px; }

.search_input input {
  min-width: 0px;
  min-height: 15px;
  z-index: 1000;
  position: absolute;
  right: 7px;
  top: -7px; }

input {
  outline: none; }

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%; }

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none; }

input[type="search"] {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAC/ElEQVQ4T5VUXUiTURg++9yf+/k255xzcw5lXmwiRVIX0ZUwHHRleJUQFbIbt0CIRexSkF1YXjgjLzIvQvBGCK9LwhsNjJLlDxSkzRQm84upm9/cvp5306Fji3rg7DvnPed93ve873Mms1gsjCCTyZhKpTI1Njbeg+1ObW2tra6uLtPR0fGlr69v2m63f3A4HGI2m2WSJBV8ylEgIyI4329oaIjqdDqtUqnMKxSKbDqdVtIZg8GQ9vv973p6ep46nc7YyclJRUKOfpDRXWTx2mq1KgYGBhanpqZ88/PzfDweNx8dHT3e3d3Nj46O3l5eXh7b2tpyIVDBuRyypqYmO4jWXC6XfGJiYq65uTmI6IIoiiyfz7OamhqGTD0mk2mlvb1dRKA3Wq12CH4i7V8EhygBXI0PBAKfQPSspaVFyGQyBSJCLpdjWK8lk8kwsuJnZ2dvwtxdKTtOLpff0Ov1otfr/eF2uz9TRuWg+sA+c3h4yFZXV6ljV+BX3LwADriKDqYw/3V6elq1U8hwD/u/kaEKSxP8ihsXwMH528HBAXXNWOnAObCnQ/0MGo0mh+Vxeb0IHKJ9BJl+Y2PDubm5aavWKdh9kA/zeDz7WH6lWpaDgwin0f5cJBK5jvkjyIHqeLZdFDOIdNDac+hQ7O3tXYP5fUWdIcJKKpWKrK+vm4LB4MNYLDa2s7Nj4XmeocsM306bzbZoNpsdPp/v+8jIiHlpaSmwvb1thWTOaIoovADUQgmnl0aj8QG+Ep5NErb9RCKhFwSBri51dXX9RBmMKAuO8OlwOPwW3R/C2b1zBZTeJolTrVZ3Y/MJin0L19PQM2ptbY0jo7nh4WEvSK8hIImYnl86FApdIiyREag+VC9qAg1aU9dIMpj78VImISMGXVJ3KXiJEILfu0T2NxAxZOGvr6+fJB+q5xnhcTQancETG5RVE2k1tLW1lQhBwDAXxsfHX+FGoYLi/3egjn40ROrv7xcgpRew8WSvePhfBv5ZOhcWFtyYq4s2if0BxdOTZpV8qu0AAAAASUVORK5CYII=) no-repeat 9px center;
  border: transparent;
  padding: 9px 10px 9px 32px;
  width: 55px;
  border-radius: 10px;
  transition: all 0.5s ease; }
  input[type="search"]:focus {
    width: 130px;
    background-color: #b4b4b4;
    border-color: #b4b4b4;
    box-shadow: 0 0 5px #b4b4b4; }
    @media (max-width: 560px) {
      input[type="search"]:focus {
        margin-top: 0px; } }

input:-moz-placeholder {
  color: #999; }

input::-webkit-input-placeholder {
  color: #999; }

#search_icon input[type="search"] {
  width: 15px;
  padding-left: 10px;
  margin-bottom: 0;
  color: transparent;
  cursor: pointer; }
  @media (max-width: 560px) {
    #search_icon input[type="search"] {
      padding-top: 5px; } }
  #search_icon input[type="search"]:hover {
    background-color: #fff; }
  #search_icon input[type="search"]:focus {
    width: 130px;
    padding-left: 32px;
    color: #6c6c6c;
    background-color: #fff;
    cursor: auto; }
    @media (max-width: 560px) {
      #search_icon input[type="search"]:focus {
        width: 80px; } }

#search_icon input:-moz-placeholder {
  color: transparent; }

#search_icon input::-webkit-input-placeholder {
  color: transparent; }

#main {
  margin-top: 148px; }
  @media (max-width: 767px) {
    #main {
      margin-top: 107px; } }
  @media (max-width: 560px) {
    #main {
      margin-top: 92px; } }

#search_icon {
  position: relative; }
  #search_icon .search_block {
    position: absolute;
    top: 38px;
    right: 16px;
    width: 160px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff;
    padding: 15px;
    text-align: center; }
    #search_icon .search_block .searchItem {
      padding: 0;
      margin: 0; }

/* Bootstrap 4 text input with search icon */
.fa-search {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAC/ElEQVQ4T5VUXUiTURg++9yf+/k255xzcw5lXmwiRVIX0ZUwHHRleJUQFbIbt0CIRexSkF1YXjgjLzIvQvBGCK9LwhsNjJLlDxSkzRQm84upm9/cvp5306Fji3rg7DvnPed93ve873Mms1gsjCCTyZhKpTI1Njbeg+1ObW2tra6uLtPR0fGlr69v2m63f3A4HGI2m2WSJBV8ylEgIyI4329oaIjqdDqtUqnMKxSKbDqdVtIZg8GQ9vv973p6ep46nc7YyclJRUKOfpDRXWTx2mq1KgYGBhanpqZ88/PzfDweNx8dHT3e3d3Nj46O3l5eXh7b2tpyIVDBuRyypqYmO4jWXC6XfGJiYq65uTmI6IIoiiyfz7OamhqGTD0mk2mlvb1dRKA3Wq12CH4i7V8EhygBXI0PBAKfQPSspaVFyGQyBSJCLpdjWK8lk8kwsuJnZ2dvwtxdKTtOLpff0Ov1otfr/eF2uz9TRuWg+sA+c3h4yFZXV6ljV+BX3LwADriKDqYw/3V6elq1U8hwD/u/kaEKSxP8ihsXwMH528HBAXXNWOnAObCnQ/0MGo0mh+Vxeb0IHKJ9BJl+Y2PDubm5aavWKdh9kA/zeDz7WH6lWpaDgwin0f5cJBK5jvkjyIHqeLZdFDOIdNDac+hQ7O3tXYP5fUWdIcJKKpWKrK+vm4LB4MNYLDa2s7Nj4XmeocsM306bzbZoNpsdPp/v+8jIiHlpaSmwvb1thWTOaIoovADUQgmnl0aj8QG+Ep5NErb9RCKhFwSBri51dXX9RBmMKAuO8OlwOPwW3R/C2b1zBZTeJolTrVZ3Y/MJin0L19PQM2ptbY0jo7nh4WEvSK8hIImYnl86FApdIiyREag+VC9qAg1aU9dIMpj78VImISMGXVJ3KXiJEILfu0T2NxAxZOGvr6+fJB+q5xnhcTQancETG5RVE2k1tLW1lQhBwDAXxsfHX+FGoYLi/3egjn40ROrv7xcgpRew8WSvePhfBv5ZOhcWFtyYq4s2if0BxdOTZpV8qu0AAAAASUVORK5CYII=) no-repeat 15px 18px; }

.has-search .form-control {
  padding-left: 2.375rem;
  margin-bottom: 0; }

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin: 0; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.home {
  min-height: 200px; }

.header_panel {
  background-color: #6c6c6c;
  padding: 90px;
  width: 100%;
  margin: 0; }
  .header_panel h1,
  .header_panel h2 {
    color: #fff;
    margin-bottom: 20px; }
  @media only screen and (max-width: 600px) {
    .header_panel {
      padding: 15px; } }

.shop_now_panel {
  padding: 30px 15px; }
  @media only screen and (max-width: 600px) {
    .shop_now_panel {
      padding: 15px; } }
  .shop_now_panel .product_panel {
    padding: 30px;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff; }
    .shop_now_panel .product_panel .product_img {
      padding: 0 30px 0px 0px; }
      @media only screen and (max-width: 600px) {
        .shop_now_panel .product_panel .product_img {
          padding: 0 15px 0px 0px; } }
    .shop_now_panel .product_panel .shop_now {
      margin: auto;
      padding: 0px 8%; }
      .shop_now_panel .product_panel .shop_now h2,
      .shop_now_panel .product_panel .shop_now h3 {
        margin-bottom: 25px; }
      @media only screen and (max-width: 600px) {
        .shop_now_panel .product_panel .shop_now {
          padding: 25px 0;
          text-align: center; } }
    .shop_now_panel .product_panel .sale {
      position: absolute; }

.register_panel .shop_now_panel {
  padding: 0px; }

.register_panel .wow_block,
.register_panel .wow_men_block {
  width: 100%;
  min-height: 345px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #00d3ca; }
  @media only screen and (max-width: 600px) {
    .register_panel .wow_block,
    .register_panel .wow_men_block {
      margin-bottom: 30px; } }

.register_panel .wow_block {
  margin-bottom: 30px; }
  .register_panel .wow_block h1 {
    padding-bottom: 20px; }

.register_panel .game_begin_block {
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background: #fff; }
  .register_panel .game_begin_block .game_sub_block {
    padding: 32px;
    text-align: center;
    margin-bottom: 10px; }
  .register_panel .game_begin_block .pop {
    position: absolute;
    top: 25px;
    left: 25px; }

.subscribe_panel {
  background-color: #b4b4b4;
  margin-top: 30px;
  padding: 45px; }
  .subscribe_panel .subscribe_block {
    text-align: center; }
    .subscribe_panel .subscribe_block .search {
      margin-right: 15px; }
    .subscribe_panel .subscribe_block .fa.fa-envelope {
      position: relative;
      left: 25px; }
      @media only screen and (max-width: 600px) {
        .subscribe_panel .subscribe_block .fa.fa-envelope {
          left: -142px;
          top: 37px; } }

img {
  width: 100%; }

.display_none {
  display: none; }

.App {
  color: black; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.react-confirm-alert-body h1 {
  font-size: 18px; }

.cart-block {
  padding: 50px 15px 25px; }
  @media (max-width: 560px) {
    .cart-block {
      padding-top: 15px; } }
  @media (max-width: 560px) {
    .cart-block .cart-top-block {
      display: none; } }
  .cart-block .cart-top-block,
  .cart-block .cart-single-block {
    color: #b4b4b4;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    border-bottom: 1px solid #b4b4b4; }
    .cart-block .cart-top-block > ul,
    .cart-block .cart-single-block > ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 560px) {
        .cart-block .cart-top-block > ul,
        .cart-block .cart-single-block > ul {
          display: inline-block; } }
      .cart-block .cart-top-block > ul li,
      .cart-block .cart-single-block > ul li {
        float: left; }
        .cart-block .cart-top-block > ul li:nth-child(1),
        .cart-block .cart-single-block > ul li:nth-child(1) {
          width: 60%;
          padding-right: 15px; }
          @media (max-width: 992px) {
            .cart-block .cart-top-block > ul li:nth-child(1),
            .cart-block .cart-single-block > ul li:nth-child(1) {
              width: 50%; } }
          @media (max-width: 767px) {
            .cart-block .cart-top-block > ul li:nth-child(1),
            .cart-block .cart-single-block > ul li:nth-child(1) {
              width: 50%; } }
          @media (max-width: 560px) {
            .cart-block .cart-top-block > ul li:nth-child(1),
            .cart-block .cart-single-block > ul li:nth-child(1) {
              width: 100%;
              padding-right: 0;
              margin-bottom: 15px; } }
        .cart-block .cart-top-block > ul li:nth-child(2),
        .cart-block .cart-single-block > ul li:nth-child(2) {
          width: 10%;
          text-align: left;
          color: #b4b4b4;
          font-weight: 700; }
          @media (max-width: 560px) {
            .cart-block .cart-top-block > ul li:nth-child(2),
            .cart-block .cart-single-block > ul li:nth-child(2) {
              margin-top: 5px; } }
        .cart-block .cart-top-block > ul li:nth-child(3),
        .cart-block .cart-single-block > ul li:nth-child(3) {
          width: 25%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media (max-width: 560px) {
            .cart-block .cart-top-block > ul li:nth-child(3),
            .cart-block .cart-single-block > ul li:nth-child(3) {
              width: 60%;
              justify-content: flex-end; } }
        .cart-block .cart-top-block > ul li:nth-child(4),
        .cart-block .cart-single-block > ul li:nth-child(4) {
          width: 10%;
          text-align: right; }
          @media (max-width: 560px) {
            .cart-block .cart-top-block > ul li:nth-child(4),
            .cart-block .cart-single-block > ul li:nth-child(4) {
              margin-top: 5px;
              width: auto;
              float: right; } }
  .cart-block .cart-single-block {
    border-bottom: none;
    font-weight: normal;
    color: #2e2e2e;
    margin-top: 30px;
    margin-bottom: 30px; }
    .cart-block .cart-single-block .img-block img {
      float: left;
      width: 100px;
      height: 100px;
      padding: 20px;
      margin-right: 30px;
      border: 1px solid #b4b4b4; }
      @media (max-width: 992px) {
        .cart-block .cart-single-block .img-block img {
          float: left;
          width: 80px;
          height: 80px;
          padding: 10px; } }
      @media (max-width: 560px) {
        .cart-block .cart-single-block .img-block img {
          margin-right: 10px; } }
    @media (max-width: 560px) {
      .cart-block .cart-single-block .img-block span {
        display: table-cell; } }
    .cart-block .cart-single-block .img-block span p {
      margin-bottom: 0; }
    .cart-block .cart-single-block .img-block span .remove a {
      font-size: 10px;
      margin-top: 5px;
      color: #2e2e2e; }
      .cart-block .cart-single-block .img-block span .remove a span {
        color: #f62f5e; }
    .cart-block .cart-single-block .quantity-block span {
      float: left; }
      .cart-block .cart-single-block .quantity-block span a {
        float: left;
        margin-right: 15px;
        background: #efefef;
        color: #2e2e2e;
        width: 25px;
        height: 25px;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%; }
        .cart-block .cart-single-block .quantity-block span a:hover, .cart-block .cart-single-block .quantity-block span a:focus {
          color: #f62f5e; }
        @media (max-width: 560px) {
          .cart-block .cart-single-block .quantity-block span a {
            margin-right: 5px; } }
    .cart-block .cart-single-block .quantity-block .number-block {
      padding: 10px;
      width: auto;
      min-width: 70px;
      margin-right: 15px;
      font-weight: 700;
      font-size: 20px;
      background: #fff;
      border: 1px solid #efefef;
      border-radius: 20px; }
      @media (max-width: 992px) {
        .cart-block .cart-single-block .quantity-block .number-block {
          min-width: 50px; } }
      @media (max-width: 560px) {
        .cart-block .cart-single-block .quantity-block .number-block {
          min-width: 35px;
          margin-right: 5px;
          padding: 5px;
          border-radius: 10px; } }
    .cart-block .cart-single-block .price {
      font-weight: 700;
      font-size: 22px;
      color: #2e2e2e; }

.cart-bottom-block {
  background: #efefef;
  padding: 20px 15px; }
  @media (max-width: 360px) {
    .cart-bottom-block {
      text-align: center; } }
  .cart-bottom-block a:last-child,
  .cart-bottom-block button:last-child {
    float: right; }
    @media (max-width: 360px) {
      .cart-bottom-block a:last-child,
      .cart-bottom-block button:last-child {
        float: none;
        display: inherit;
        margin: 15px auto; } }

.react-confirm-alert-body-element .react-confirm-alert-button-group > button {
  background: #f62f5e;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid #f62f5e;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  transition: all 0.25s ease;
  border-radius: 20px; }
  .react-confirm-alert-body-element .react-confirm-alert-button-group > button:hover {
    background: #fff;
    color: #2e2e2e;
    border: 1px solid #f62f5e; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
body .header .open.navbar-collapse {
  display: inline-flex !important;
  top: 0;
  position: absolute;
  background: #fff3f3;
  width: 100%;
  left: 0;
  visibility: visible;
  /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
  box-shadow: 0px 1px 1px #61484e; }

.header nav.navbar {
  width: 100%;
  margin: 0; }

.product_filter_panel {
  position: relative;
  top: 20px; }
  .product_filter_panel h2 {
    text-transform: capitalize; }

body .header .head-right {
  margin-top: -68px; }

body .header .closed.navbar-collapse {
  visibility: hidden;
  /* hides sub-menu */
  opacity: 0;
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  transform: translateY(-2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; }

body .header .navbar-nav {
  flex-direction: column;
  display: inline-table;
  padding: 15px;
  width: 100%; }
  body .header .navbar-nav li {
    float: left;
    width: 33.3%;
    padding: 5px; }

.hamburger-menu {
  display: flex;
  position: absolute;
  top: 30px;
  right: 100px; }
  .hamburger-menu > div {
    left: 6px;
    top: 5px; }

.topbar {
  padding: 15px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  min-height: 50px;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background: #f7f7f7; }
  @media (max-width: 767px) {
    .topbar {
      min-height: inherit;
      padding: 5px 0; } }
  .topbar .register-block {
    float: left;
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .topbar .register-block {
        text-align: center;
        width: auto;
        margin-top: 0; } }
    .topbar .register-block .login-name-block {
      margin: 8px 0 8px 10px;
      font-weight: 600; }
      @media (max-width: 767px) {
        .topbar .register-block .login-name-block {
          margin: 0 0 0 10px; } }
      @media (max-width: 560px) {
        .topbar .register-block .login-name-block {
          font-size: 0.85rem; } }
    .topbar .register-block a {
      display: inline-block; }
      @media (max-width: 767px) {
        .topbar .register-block a {
          padding: 0 1rem; } }
  .topbar .topbar-menu {
    float: left;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991px) {
      .topbar .topbar-menu {
        width: 45%; } }
    @media (max-width: 767px) {
      .topbar .topbar-menu {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px; } }
    .topbar .topbar-menu ul {
      padding: 0;
      margin: 0; }
      .topbar .topbar-menu ul li {
        float: left;
        margin-right: 25px; }
        @media (max-width: 991px) {
          .topbar .topbar-menu ul li {
            margin-right: 10px; } }
        .topbar .topbar-menu ul li a {
          color: #2e2e2e; }
          .topbar .topbar-menu ul li a:hover, .topbar .topbar-menu ul li a:focus {
            color: #f62f5e; }
  .topbar .currency-block {
    float: left;
    padding-left: 30px; }
    @media (max-width: 1200px) {
      .topbar .currency-block {
        padding-left: 0; } }
  .topbar .price-block {
    float: right;
    margin-top: 8px; }
    @media (min-width: 767px) and (max-width: 991px) {
      .topbar .price-block {
        width: 40%;
        text-align: right; } }
    @media (max-width: 767px) {
      .topbar .price-block {
        margin-top: 0; } }
    @media (max-width: 560px) {
      .topbar .price-block span {
        display: none; } }
    .topbar .price-block a {
      color: #2e2e2e;
      padding: 0 0 0 10px; }
      @media (max-width: 767px) {
        .topbar .price-block a {
          padding: 0 1rem; } }
      .topbar .price-block a:hover, .topbar .price-block a:focus {
        color: #f62f5e; }
    .topbar .price-block .signout-block {
      display: inline-block; }
      .topbar .price-block .signout-block a {
        color: #f62f5e;
        padding-right: 0; }
        .topbar .price-block .signout-block a:hover, .topbar .price-block .signout-block a:focus {
          color: #2e2e2e; }

.header {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 65px;
  border-bottom: 1px solid #b4b4b4; }
  @media (max-width: 767px) {
    .header {
      top: 34px; } }
  .header .head-inner {
    position: relative; }
  .header .logo_block {
    width: auto;
    float: left;
    margin-top: 10px;
    cursor: pointer; }
    @media (max-width: 767px) {
      .header .logo_block {
        margin-top: 0; } }
    .header .logo_block a {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.1em;
      font-family: "Montserrat", sans-serif; }
      @media (max-width: 560px) {
        .header .logo_block a {
          font-size: 18px; } }
    .header .logo_block img {
      padding: 10px 0;
      max-width: 70%; }
      @media (max-width: 560px) {
        .header .logo_block img {
          max-width: 50%; } }
  .header nav {
    float: left;
    width: 66%;
    padding: 0;
    margin: 20px 0 0 -90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 1199px) {
      .header nav {
        width: 70%; } }
    @media (max-width: 991px) {
      .header nav {
        width: 100%;
        float: right;
        display: block;
        margin: 0; } }
    @media (min-width: 992px) {
      .header nav .navbar-collapse {
        display: block !important; } }
    .header nav .navbar-nav .nav-item .nav-link.active {
      color: #f62f5e; }
    .header nav .navbar-nav .nav-link {
      color: #2e2e2e;
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      padding: 10px 20px; }
      @media (max-width: 991px) {
        .header nav .navbar-nav .nav-link {
          padding: 10px 0;
          border-bottom: 1px solid #2e2e2e; } }
      .header nav .navbar-nav .nav-link:hover {
        color: #f62f5e; }
    @media (max-width: 991px) {
      .header nav .navbar-nav .nav-item:last-child a {
        border: none;
        padding-bottom: 50px; } }
  .header .head-right {
    width: auto;
    float: right;
    margin-top: 30px; }
    @media (max-width: 991px) {
      .header .head-right {
        position: absolute;
        right: 50px;
        margin-top: 45px; } }
    @media (max-width: 560px) {
      .header .head-right {
        margin-top: 30px;
        right: 45px; } }
    .header .head-right ul li {
      position: relative;
      float: left;
      margin-right: 15px; }
      .header .head-right ul li a {
        color: #2e2e2e; }
        .header .head-right ul li a:hover, .header .head-right ul li a:focus {
          color: #f62f5e; }
        .header .head-right ul li a .cart_items {
          position: absolute;
          border-radius: 50%;
          width: auto;
          height: auto;
          min-width: 20px;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          color: white;
          padding: 0 8px;
          bottom: 15px;
          right: -10px;
          background-color: #f62f5e; }
      .header .head-right ul li:last-child {
        margin-right: 0; }
  .header .navbar-toggler {
    border: none;
    padding: 12px 0 0 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -68px; }
    @media (max-width: 767px) {
      .header .navbar-toggler {
        top: -60px; } }
    @media (max-width: 560px) {
      .header .navbar-toggler {
        top: -55px; } }
    .header .navbar-toggler:hover, .header .navbar-toggler:focus {
      outline: none; }
      .header .navbar-toggler:hover .toggler-icon, .header .navbar-toggler:focus .toggler-icon {
        background: #f62f5e;
        cursor: pointer; }
    .header .navbar-toggler .toggler-icon {
      width: 20px;
      height: 2px;
      background: #6c6c6c;
      display: block;
      margin-bottom: 5px; }

.search_input input {
  min-width: 0px;
  min-height: 15px;
  z-index: 1000;
  position: absolute;
  right: 7px;
  top: -7px; }

input {
  outline: none; }

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%; }

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none; }

input[type="search"] {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAC/ElEQVQ4T5VUXUiTURg++9yf+/k255xzcw5lXmwiRVIX0ZUwHHRleJUQFbIbt0CIRexSkF1YXjgjLzIvQvBGCK9LwhsNjJLlDxSkzRQm84upm9/cvp5306Fji3rg7DvnPed93ve873Mms1gsjCCTyZhKpTI1Njbeg+1ObW2tra6uLtPR0fGlr69v2m63f3A4HGI2m2WSJBV8ylEgIyI4329oaIjqdDqtUqnMKxSKbDqdVtIZg8GQ9vv973p6ep46nc7YyclJRUKOfpDRXWTx2mq1KgYGBhanpqZ88/PzfDweNx8dHT3e3d3Nj46O3l5eXh7b2tpyIVDBuRyypqYmO4jWXC6XfGJiYq65uTmI6IIoiiyfz7OamhqGTD0mk2mlvb1dRKA3Wq12CH4i7V8EhygBXI0PBAKfQPSspaVFyGQyBSJCLpdjWK8lk8kwsuJnZ2dvwtxdKTtOLpff0Ov1otfr/eF2uz9TRuWg+sA+c3h4yFZXV6ljV+BX3LwADriKDqYw/3V6elq1U8hwD/u/kaEKSxP8ihsXwMH528HBAXXNWOnAObCnQ/0MGo0mh+Vxeb0IHKJ9BJl+Y2PDubm5aavWKdh9kA/zeDz7WH6lWpaDgwin0f5cJBK5jvkjyIHqeLZdFDOIdNDac+hQ7O3tXYP5fUWdIcJKKpWKrK+vm4LB4MNYLDa2s7Nj4XmeocsM306bzbZoNpsdPp/v+8jIiHlpaSmwvb1thWTOaIoovADUQgmnl0aj8QG+Ep5NErb9RCKhFwSBri51dXX9RBmMKAuO8OlwOPwW3R/C2b1zBZTeJolTrVZ3Y/MJin0L19PQM2ptbY0jo7nh4WEvSK8hIImYnl86FApdIiyREag+VC9qAg1aU9dIMpj78VImISMGXVJ3KXiJEILfu0T2NxAxZOGvr6+fJB+q5xnhcTQancETG5RVE2k1tLW1lQhBwDAXxsfHX+FGoYLi/3egjn40ROrv7xcgpRew8WSvePhfBv5ZOhcWFtyYq4s2if0BxdOTZpV8qu0AAAAASUVORK5CYII=) no-repeat 9px center;
  border: transparent;
  padding: 9px 10px 9px 32px;
  width: 55px;
  border-radius: 10px;
  transition: all 0.5s ease; }
  input[type="search"]:focus {
    width: 130px;
    background-color: #b4b4b4;
    border-color: #b4b4b4;
    box-shadow: 0 0 5px #b4b4b4; }
    @media (max-width: 560px) {
      input[type="search"]:focus {
        margin-top: 0px; } }

input:-moz-placeholder {
  color: #999; }

input::-webkit-input-placeholder {
  color: #999; }

#search_icon input[type="search"] {
  width: 15px;
  padding-left: 10px;
  margin-bottom: 0;
  color: transparent;
  cursor: pointer; }
  @media (max-width: 560px) {
    #search_icon input[type="search"] {
      padding-top: 5px; } }
  #search_icon input[type="search"]:hover {
    background-color: #fff; }
  #search_icon input[type="search"]:focus {
    width: 130px;
    padding-left: 32px;
    color: #6c6c6c;
    background-color: #fff;
    cursor: auto; }
    @media (max-width: 560px) {
      #search_icon input[type="search"]:focus {
        width: 80px; } }

#search_icon input:-moz-placeholder {
  color: transparent; }

#search_icon input::-webkit-input-placeholder {
  color: transparent; }

#main {
  margin-top: 148px; }
  @media (max-width: 767px) {
    #main {
      margin-top: 107px; } }
  @media (max-width: 560px) {
    #main {
      margin-top: 92px; } }

#search_icon {
  position: relative; }
  #search_icon .search_block {
    position: absolute;
    top: 38px;
    right: 16px;
    width: 160px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff;
    padding: 15px;
    text-align: center; }
    #search_icon .search_block .searchItem {
      padding: 0;
      margin: 0; }

/* Bootstrap 4 text input with search icon */
.fa-search {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAC/ElEQVQ4T5VUXUiTURg++9yf+/k255xzcw5lXmwiRVIX0ZUwHHRleJUQFbIbt0CIRexSkF1YXjgjLzIvQvBGCK9LwhsNjJLlDxSkzRQm84upm9/cvp5306Fji3rg7DvnPed93ve873Mms1gsjCCTyZhKpTI1Njbeg+1ObW2tra6uLtPR0fGlr69v2m63f3A4HGI2m2WSJBV8ylEgIyI4329oaIjqdDqtUqnMKxSKbDqdVtIZg8GQ9vv973p6ep46nc7YyclJRUKOfpDRXWTx2mq1KgYGBhanpqZ88/PzfDweNx8dHT3e3d3Nj46O3l5eXh7b2tpyIVDBuRyypqYmO4jWXC6XfGJiYq65uTmI6IIoiiyfz7OamhqGTD0mk2mlvb1dRKA3Wq12CH4i7V8EhygBXI0PBAKfQPSspaVFyGQyBSJCLpdjWK8lk8kwsuJnZ2dvwtxdKTtOLpff0Ov1otfr/eF2uz9TRuWg+sA+c3h4yFZXV6ljV+BX3LwADriKDqYw/3V6elq1U8hwD/u/kaEKSxP8ihsXwMH528HBAXXNWOnAObCnQ/0MGo0mh+Vxeb0IHKJ9BJl+Y2PDubm5aavWKdh9kA/zeDz7WH6lWpaDgwin0f5cJBK5jvkjyIHqeLZdFDOIdNDac+hQ7O3tXYP5fUWdIcJKKpWKrK+vm4LB4MNYLDa2s7Nj4XmeocsM306bzbZoNpsdPp/v+8jIiHlpaSmwvb1thWTOaIoovADUQgmnl0aj8QG+Ep5NErb9RCKhFwSBri51dXX9RBmMKAuO8OlwOPwW3R/C2b1zBZTeJolTrVZ3Y/MJin0L19PQM2ptbY0jo7nh4WEvSK8hIImYnl86FApdIiyREag+VC9qAg1aU9dIMpj78VImISMGXVJ3KXiJEILfu0T2NxAxZOGvr6+fJB+q5xnhcTQancETG5RVE2k1tLW1lQhBwDAXxsfHX+FGoYLi/3egjn40ROrv7xcgpRew8WSvePhfBv5ZOhcWFtyYq4s2if0BxdOTZpV8qu0AAAAASUVORK5CYII=) no-repeat 15px 18px; }

.has-search .form-control {
  padding-left: 2.375rem;
  margin-bottom: 0; }

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin: 0; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.home {
  min-height: 200px; }

.header_panel {
  background-color: #6c6c6c;
  padding: 90px;
  width: 100%;
  margin: 0; }
  .header_panel h1,
  .header_panel h2 {
    color: #fff;
    margin-bottom: 20px; }
  @media only screen and (max-width: 600px) {
    .header_panel {
      padding: 15px; } }

.shop_now_panel {
  padding: 30px 15px; }
  @media only screen and (max-width: 600px) {
    .shop_now_panel {
      padding: 15px; } }
  .shop_now_panel .product_panel {
    padding: 30px;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff; }
    .shop_now_panel .product_panel .product_img {
      padding: 0 30px 0px 0px; }
      @media only screen and (max-width: 600px) {
        .shop_now_panel .product_panel .product_img {
          padding: 0 15px 0px 0px; } }
    .shop_now_panel .product_panel .shop_now {
      margin: auto;
      padding: 0px 8%; }
      .shop_now_panel .product_panel .shop_now h2,
      .shop_now_panel .product_panel .shop_now h3 {
        margin-bottom: 25px; }
      @media only screen and (max-width: 600px) {
        .shop_now_panel .product_panel .shop_now {
          padding: 25px 0;
          text-align: center; } }
    .shop_now_panel .product_panel .sale {
      position: absolute; }

.register_panel .shop_now_panel {
  padding: 0px; }

.register_panel .wow_block,
.register_panel .wow_men_block {
  width: 100%;
  min-height: 345px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #00d3ca; }
  @media only screen and (max-width: 600px) {
    .register_panel .wow_block,
    .register_panel .wow_men_block {
      margin-bottom: 30px; } }

.register_panel .wow_block {
  margin-bottom: 30px; }
  .register_panel .wow_block h1 {
    padding-bottom: 20px; }

.register_panel .game_begin_block {
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background: #fff; }
  .register_panel .game_begin_block .game_sub_block {
    padding: 32px;
    text-align: center;
    margin-bottom: 10px; }
  .register_panel .game_begin_block .pop {
    position: absolute;
    top: 25px;
    left: 25px; }

.subscribe_panel {
  background-color: #b4b4b4;
  margin-top: 30px;
  padding: 45px; }
  .subscribe_panel .subscribe_block {
    text-align: center; }
    .subscribe_panel .subscribe_block .search {
      margin-right: 15px; }
    .subscribe_panel .subscribe_block .fa.fa-envelope {
      position: relative;
      left: 25px; }
      @media only screen and (max-width: 600px) {
        .subscribe_panel .subscribe_block .fa.fa-envelope {
          left: -142px;
          top: 37px; } }

img {
  width: 100%; }

.display_none {
  display: none; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
footer {
  padding: 50px 0; }
  footer ul li {
    font-size: 20px;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      footer ul li {
        font-size: 16px; } }
    footer ul li a {
      color: #2e2e2e;
      font-size: 16px; }
      footer ul li a:hover, footer ul li a:focus {
        color: #f62f5e; }

.developed_logo li img {
  max-width: 60%;
  cursor: pointer; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.hero-section.categories {
  background-size: cover;
  background-position: center;
  min-height: 500px;
  position: relative; }
  .hero-section.categories .category-list {
    position: relative;
    padding: 50px 30px;
    color: white; }
    @media (max-width: 767px) {
      .hero-section.categories .category-list {
        padding: 25px 0px; } }
    .hero-section.categories .category-list .category-header {
      text-transform: capitalize;
      color: white; }

.hero-section.categories:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); }

.shop_brand_panel {
  background-color: #ccc; }
  .shop_brand_panel .shop_brand_block {
    padding: 60px; }

.hot_brand_panel {
  padding: 0 0 30px 0; }
  .hot_brand_panel .hot_block {
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff;
    padding: 25px;
    text-align: center; }
    .hot_brand_panel .hot_block .hot {
      position: absolute;
      text-align: left; }

.product_filter_panel .filter_block {
  padding: 30px 0; }

.product_filter_panel .filter_items {
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background: #fff;
  padding: 30px; }

.product_filter_panel .filter_apply {
  display: flex; }
  .product_filter_panel .filter_apply .red {
    padding: 7px;
    margin-left: 20px; }

.items_block {
  padding-top: 30px; }
  .items_block .hot_block {
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    background: #fff;
    padding: 25px;
    text-align: center; }
    .items_block .hot_block .hot {
      position: absolute;
      text-align: left; }
  .items_block .item {
    margin-bottom: 30px;
    display: inline-block; }
  .items_block .breadcrumb-sub-cat-name {
    font-size: 18px; }
    .items_block .breadcrumb-sub-cat-name a {
      font-size: 18px;
      color: #f62f5e;
      font-weight: 700;
      cursor: pointer; }
      .items_block .breadcrumb-sub-cat-name a:focus, .items_block .breadcrumb-sub-cat-name a:hover {
        color: #2e2e2e; }

.category_subscribe {
  padding: 15px; }
  .category_subscribe .subscribe_for_shop {
    display: flex;
    align-items: center; }
    .category_subscribe .subscribe_for_shop h3 {
      float: left;
      margin: 0; }
    .category_subscribe .subscribe_for_shop .input_search {
      padding-left: 30px;
      float: right; }
    .category_subscribe .subscribe_for_shop input {
      margin-bottom: 0;
      margin-right: 20px; }

.sub_categories li .sub_categories_submit {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0; }
  .sub_categories li .sub_categories_submit:hover {
    color: #f62f5e; }
    .sub_categories li .sub_categories_submit:hover .category_name {
      color: #f62f5e;
      transition: all 0.25s ease; }

.sub_categories .category_name {
  color: #fff; }

.add_to_cart {
  display: flex;
  justify-content: center; }
  .add_to_cart h3 {
    opacity: 0;
    margin-bottom: 0;
    font-size: 12px;
    transition: all 0.5s; }

.show {
  display: block !important;
  opacity: 1 !important; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.alice-carousel {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  direction: ltr; }

.alice-carousel__wrapper {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto; }

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap; }
  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0; }
    .alice-carousel__stage-item * {
      line-height: normal;
      line-height: initial; }
    .alice-carousel__stage-item.__cloned {
      visibility: hidden;
      opacity: 0; }

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 15px 10px; }
  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize; }

.alice-carousel__prev-btn {
  text-align: right; }

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  color: #465798; }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred; }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: 0.4; }

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block; }
  .alice-carousel__play-btn:hover {
    cursor: pointer; }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff; }

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent; }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    position: absolute;
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    content: '';
    transition: all 0.3s linear;
    border-width: 8px 0 8px 15px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #465798; }
  .alice-carousel__play-btn-item::before {
    left: 5px;
    height: 14px; }
  .alice-carousel__play-btn-item::after {
    top: 7px;
    left: 18px; }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    height: 30px;
    border-width: 0 0 0 10px; }
  .alice-carousel__play-btn-item.__pause::after {
    top: 0;
    left: 18px; }

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center; }
  .alice-carousel__dots-item {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb; }
    .alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
      background-color: #6e7ebc; }

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6); }
  .alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0; }

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.alice-carousel .animated-out {
  z-index: 1; }

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

img {
  max-width: 100%;
  height: auto; }

.hot_block a img {
  width: auto;
  max-height: 150px;
  height: 150px; }

.product-block {
  padding: 20px 15px; }
  @media (max-width: 767px) {
    .product-block {
      padding: 25px 15px; } }

.product_images_block {
  display: none; }
  @media (max-width: 767px) {
    .product_images_block {
      display: block; } }

.breadcrumbs ul {
  padding: 0;
  margin: 0; }
  .breadcrumbs ul li {
    float: left;
    margin: 0px; }
    .breadcrumbs ul li .arrow {
      margin: 0 5px; }
    .breadcrumbs ul li a {
      color: #6c6c6c;
      font-weight: 600; }
      .breadcrumbs ul li a:hover, .breadcrumbs ul li a:focus {
        color: #f62f5e; }

@media (max-width: 767px) {
  .item-left-block {
    display: none; } }

.item-left-block .top-image {
  position: relative;
  height: 400px; }
  .item-left-block .top-image img {
    position: absolute;
    margin: auto;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    height: 100%;
    width: inherit; }

.item-left-block .colors-selection-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: absolute;
  width: 100%;
  left: 0; }
  .item-left-block .colors-selection-block ul {
    padding: 0;
    margin: 0; }
    .item-left-block .colors-selection-block ul li {
      width: 75px;
      height: 75px;
      float: left;
      margin-right: 15px; }
      .item-left-block .colors-selection-block ul li img {
        height: 100%; }
    .item-left-block .colors-selection-block ul :last-child {
      margin-right: 0; }
    .item-left-block .colors-selection-block ul .active {
      border: 1px solid #f62f5e; }

@media (max-width: 767px) {
  .item-right-block {
    margin-top: 0px; } }

.item-right-block .item-title {
  margin-right: 50px; }

.item-right-block .amount-block {
  font-weight: 700;
  position: relative;
  color: #f62f5e;
  font-size: 24px;
  display: flex; }
  .item-right-block .amount-block h6.pricetag {
    color: #2e2e2e;
    padding-right: 8px;
    margin-top: 0.32rem; }
  .item-right-block .amount-block p {
    border-bottom: 3px solid black;
    width: 60px;
    position: absolute;
    top: 28px;
    left: 72px; }

.item-right-block .quantity-block ul {
  padding: 0;
  margin: 0; }
  .item-right-block .quantity-block ul li {
    float: left;
    margin-right: 15px;
    background: #efefef;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; }
    .item-right-block .quantity-block ul li a {
      color: #2e2e2e;
      font-weight: 700;
      font-size: 24px; }
  .item-right-block .quantity-block ul .number-block {
    padding: 15px;
    width: auto;
    min-width: 80px;
    font-weight: 700;
    font-size: 20px;
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 20px; }

@media (max-width: 560px) {
  .item-right-block .btn-block {
    text-align: center;
    padding-top: 0.1rem !important; } }

.item-right-block .btn-block .add_to_cart {
  display: block;
  margin-left: 45px; }

.item-right-block .wish-list {
  font-weight: 500;
  margin: 10px 50px;
  font-size: 14px;
  float: right; }
  @media (max-width: 992px) {
    .item-right-block .wish-list {
      margin: 10px 0; } }
  @media (max-width: 560px) {
    .item-right-block .wish-list {
      float: left;
      text-align: center;
      width: 100%; } }
  .item-right-block .wish-list a {
    color: #6c6c6c; }
    .item-right-block .wish-list a span {
      color: #f62f5e;
      margin-right: 5px;
      font-size: 24px;
      font-weight: 700;
      position: relative;
      top: 3px; }
    .item-right-block .wish-list a:hover, .item-right-block .wish-list a:focus {
      color: #f62f5e; }

.review-block {
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5; }
  @media (max-width: 767px) {
    .review-block {
      padding: 0 15px; } }
  .review-block .author-block {
    color: #2e2e2e; }
    .review-block .author-block h6 {
      margin-bottom: 0; }
    .review-block .author-block span {
      font-size: 12px;
      color: #6c6c6c; }
  .review-block .starts-block {
    display: inline-block; }
  .review-block .comment-block {
    padding: 0;
    margin: 0; }
    .review-block .comment-block li {
      float: left;
      margin-right: 30px;
      display: flex;
      align-items: center; }
      .review-block .comment-block li span {
        background: #b4b4b4;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: #f62f5e;
        font-size: 24px;
        border-radius: 50%; }
      .review-block .comment-block li:last-child span {
        font-size: 16px; }

form .form-group {
  margin-bottom: 0; }
  form .form-group label {
    float: left;
    color: #2e2e2e;
    font-weight: 700; }
    @media (max-width: 767px) {
      form .form-group label {
        width: 100%; } }
  form .form-group span {
    width: auto;
    margin-left: 25%;
    margin-top: -25px;
    font-size: 12px;
    float: left;
    position: relative;
    z-index: 1; }
    @media (max-width: 767px) {
      form .form-group span {
        margin-left: 0; } }

.product-image-block {
  text-align: center;
  padding: 30px 20px;
  box-shadow: 0 2px 3px -2px black;
  border: 1px solid #9e9e9e; }
  @media (max-width: 991px) {
    .product-image-block {
      margin-bottom: 30px; } }
  .product-image-block .price {
    color: #f62f5e;
    font-size: 20px;
    font-weight: 700; }
  .product-image-block:hover {
    box-shadow: 0px 10px 50px -5px #2e2e2e; }

.addtocart_btn {
  color: #fff; }
  .addtocart_btn:hover {
    color: #2e2e2e; }

.productlocations_breadcrumb {
  display: inline-flex; }
  .productlocations_breadcrumb h6 {
    margin-top: 0.2rem; }

.mb_price {
  margin-bottom: 132px; }

.clip_loader {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center; }

.carousel {
  margin: 0 60px; }

.productrecommendations_item {
  margin: 0 15px; }
  .productrecommendations_item:first-child {
    margin-left: 0; }
    @media (max-width: 767px) {
      .productrecommendations_item:first-child {
        margin-left: 15px; } }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.checkout_information {
  margin-bottom: 60px;
  margin-top: 60px;
  display: inline-block;
  background: #fff;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background: #fff; }
  .checkout_information .checkout_block {
    padding: 60px; }
    @media (max-width: 767px) {
      .checkout_information .checkout_block {
        padding: 15px; } }
    .checkout_information .checkout_block .progress-txt {
      display: flex; }
      @media (max-width: 767px) {
        .checkout_information .checkout_block .progress-txt {
          display: none; } }
      .checkout_information .checkout_block .progress-txt li {
        width: 100%;
        padding-right: 0; }
        .checkout_information .checkout_block .progress-txt li:last-child {
          width: 27%; }
  .checkout_information .success-block {
    width: 100%;
    padding-top: 50px; }
    @media (max-width: 767px) {
      .checkout_information .success-block {
        padding: 15px; } }
  .checkout_information ul {
    padding-top: 20px; }
    .checkout_information ul li {
      display: inline;
      list-style-type: none;
      padding-right: 16%;
      float: left; }
    .checkout_information ul .bar {
      position: relative;
      margin: 0 5px 30px;
      border-radius: 0;
      content: "";
      background: #b4b4b4;
      left: 0px;
      top: 6px;
      width: 28%;
      height: 8px; }
      @media (max-width: 992px) {
        .checkout_information ul .bar {
          width: 26%; } }
      @media (max-width: 767px) {
        .checkout_information ul .bar {
          width: 24.5%; } }
      @media (max-width: 480px) {
        .checkout_information ul .bar {
          width: 17%; } }
    .checkout_information ul .dot {
      background: #b4b4b4;
      padding-right: 0;
      margin: 0; }

.active_next {
  background: #f62f5e !important; }

.checkout_next {
  padding: 25px 0px;
  width: 100%; }
  @media (max-width: 767px) {
    .checkout_next {
      padding: 15px; } }
  .checkout_next .back {
    margin-left: 0px; }
    @media (max-width: 767px) {
      .checkout_next .back {
        margin-left: 0; } }
  .checkout_next .next_step {
    float: right;
    margin-right: 0px; }
    @media (max-width: 767px) {
      .checkout_next .next_step {
        margin-right: 0; } }

.form-check {
  padding: 0px; }
  .form-check label {
    display: block; }
  .form-check .address {
    word-break: break-word; }

.form-check-input {
  position: relative;
  margin: 0 20px 0 0;
  min-width: 24px;
  font-size: 10px;
  top: 20px; }

.delivery_options,
.country {
  margin: 0; }
  .delivery_options h3,
  .country h3 {
    display: inline-block;
    padding: 0 10px; }

.form-group.delivery_options {
  border-top: 1px solid #b4b4b4;
  padding-top: 30px; }

.delivery_block,
.conformation_block {
  padding-top: 45px;
  width: 100%; }

.delivery_block .form-group input {
  min-width: 40px; }
  @media (max-width: 767px) {
    .delivery_block .form-group input {
      width: 100%; } }

.delivery_block .form-group .radio-checkbox-block label {
  display: block;
  margin-left: 30px; }

.delivery_block .form-group select {
  width: 48%; }
  @media (max-width: 767px) {
    .delivery_block .form-group select {
      width: 100%; } }

table.order_summery {
  border-collapse: collapse;
  width: 100%; }
  table.order_summery th,
  table.order_summery td {
    padding: 8px;
    text-align: left; }
  table.order_summery tr:hover {
    background-color: #f5f5f5; }

.payment_block {
  padding: 25px 0;
  width: 100%; }
  .payment_block .payment_options {
    display: grid;
    margin-bottom: 25px; }
  .payment_block .validthru {
    min-width: 50px; }
  .payment_block .paypal {
    width: 50%;
    margin: 30px 0;
    border: 1px solid; }
    .payment_block .paypal .radio-checkbox-block {
      justify-content: center; }
    .payment_block .paypal .paypal_img {
      display: flex;
      justify-content: center; }
      .payment_block .paypal .paypal_img img {
        padding-top: 25px;
        width: 25%;
        height: 25%; }
  .payment_block .cvv_text {
    padding-top: 30px; }

.StripeElement {
  min-height: 50px;
  margin-bottom: 25px;
  display: grid;
  align-items: center;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.address_select_block select {
  width: 48%; }

.save_address {
  margin-right: 20px; }

.address_block .item {
  display: grid; }

.address_block .hot_block {
  text-align: left;
  padding: 0;
  display: grid; }

.address_block .address {
  padding: 25px;
  padding-bottom: 0;
  cursor: pointer; }
  .address_block .address .fa-plus {
    font-size: 60px;
    color: #decdcd;
    padding: 24px; }
  .address_block .address:hover {
    background-color: #b4b4b4;
    opacity: 0.75; }

.address_block .default {
  border: 2px solid #f62f5e; }

.address_block .next_step {
  padding: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.active_stage {
  color: #f62f5e; }

.modal-open .modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.75); }

.modal-open .edit-address-popup .form-content {
  padding: 20px; }

.modal-open .edit-address-popup input.form-control {
  margin-bottom: 0;
  min-height: 36px; }

.modal-open .edit-address-popup .form-check,
.modal-open .edit-address-popup .selectpicker {
  margin: 0; }

.add-address-block .hot_block {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dotted; }

.woo_region.selectpicker {
  display: block; }

/*

General variables

*/
/*

Mixins for multiple times usage

*/
.signin-form input {
  width: 100%; }

.signin-form .radio-checkbox-block input[type="checkbox"] {
  float: left;
  width: auto;
  min-width: inherit;
  min-height: inherit;
  cursor: pointer; }

.signin-form .radio-checkbox-block span {
  cursor: pointer; }

/* Facebook */
.facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
  position: relative;
  padding: 5px 15px 5px 45px;
  margin-bottom: 15px;
  display: inline-block;
  width: 150px;
  color: #fff;
  border-radius: 5px; }
  .facebook:hover, .facebook:focus {
    color: #fff; }

.facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 6px 6px no-repeat;
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 35px;
  height: 30px; }

.facebook:hover,
.facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1); }

/* Google */
.google {
  background: #dd4b39;
  margin-right: 20px;
  padding: 5px 15px 5px 45px;
  margin-bottom: 15px;
  display: inline-block;
  width: 150px;
  color: #fff;
  border-radius: 5px; }
  .google:hover, .google:focus {
    color: #fff; }

.google:before {
  content: "";
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png") 6px 6px no-repeat;
  position: absolute;
  left: 15px;
  top: 1px;
  width: 35px;
  height: 30px; }

.google:hover,
.google:focus {
  background: #e74b37; }

